<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form
      autocomplete="off"
      method="post"
      @submit.prevent="addOrUpdateTreasury"
    >
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-12'"
            :id="'branchToken'"
            :value="treasuryData.branchToken"
            :options="branchTokenOptions"
            v-on:changeValue="treasuryData.branchToken = $event"
            :title="$t('selectBranch')"
            :imgName="'branches.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'treasuryNameAr'"
            :value="treasuryData.treasuryNameAr"
            :title="$t('treasuryNameAr')"
            :imgName="'treasuries.svg'"
            v-on:changeValue="treasuryData.treasuryNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'treasuryNameEn'"
            :value="treasuryData.treasuryNameEn"
            :title="$t('treasuryNameEn')"
            :imgName="'treasuries.svg'"
            v-on:changeValue="treasuryData.treasuryNameEn = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'treasuryNameUnd'"
            :value="treasuryData.treasuryNameUnd"
            :title="$t('treasuryNameUnd')"
            :imgName="'treasuries.svg'"
            v-on:changeValue="treasuryData.treasuryNameUnd = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'treasuryCode'"
            :value="treasuryData.treasuryCode"
            :title="$t('treasuryCode')"
            :imgName="'number.svg'"
            v-on:changeValue="treasuryData.treasuryCode = $event"
          />

          <TextArea
            :className="'col-md-12'"
            :id="'treasuryNote'"
            :value="treasuryData.treasuryNote"
            :title="$t('notes')"
            :imgName="'notes.svg'"
            v-on:changeValue="treasuryData.treasuryNote = $event"
          />
        </div>
      </div>

      <div class="form-footer">
        <button name="submit" type="submit" class="btn btn-submit">
          {{ submitName }}
        </button>
        <router-link to="/treasuries" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import privilegeFinanceMixin from "@/utils/privilege-finance-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import DEFAULT_IMG from "@/assets/images/treasuries.svg";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import TextArea from "@/components/general/TextArea.vue";

export default {
  name: "TreasuryForm",
  mixins: [privilegeFinanceMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      defaultImg: DEFAULT_IMG,
      branchTokenOptions: [],
    };
  },
  props: ["treasuryData", "submitName"],
  methods: {
    async getDialogOfBranches() {
      this.isLoading = true;
      this.branchTokenOptions = [];
      this.branchTokenOptions.push({
        value: "",
        text: this.$t("selectBranch"),
      });

      let branchPrivilages = [];
      let isMasterAdmin =
        this.employeeTypeToken == this.employeeType.FounderOfSystem
          ? true
          : false;
      if (isMasterAdmin) {
        branchPrivilages = this.$store.getters.userData.employeeBranchPrivilage;
      } else {
        if (this.submitName == this.$t("add")) {
          branchPrivilages =
            this.dialogOfBranchesFinancePrivilageAdd
              .dialogOfBranchesTreasuriesAdd;
        } else {
          branchPrivilages =
            this.dialogOfBranchesFinancePrivilageActions
              .dialogOfBranchesTreasuriesUpdate;
        }
      }
      if (branchPrivilages.length) {
        for (let item in branchPrivilages) {
          this.branchTokenOptions.push({
            value: branchPrivilages[item]["branchToken"],
            text: this.setDataMultiLang(
              this.language,
              branchPrivilages[item]["branchNameAr"],
              branchPrivilages[item]["branchNameEn"]
            ),
          });
        }
      }
      this.isLoading = false;
    },
    async addOrUpdateTreasury() {
      this.$emit("addOrUpdateTreasury");
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      if (lang == "en") return dataEn;
      else return dataAr;
    },
  },
  watch: {},
  async created() {
    this.getDialogOfBranches();
  },
};
</script>

<style lang="scss"></style>
