<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <EmployeeBranchForm
      v-if="!isLoading"
      :employeeBranchData="employeeBranches.employeeBranch"
      v-on:addOrUpdateEmployeeBranch="addOrUpdateEmployeeBranch"
      :submitName="$t('add')"
      :employeesArray="employeesArray"
      :exceptionMsgEmployee="exceptionMsgEmployee"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS, BASE_URL } from "@/utils/constants";

import Employees from "@/models/employees/employees/Employees";
import EmployeeBranchForm from "@/components/employees/employeeBranches/EmployeeBranchForm.vue";
import EmployeeBranches from "@/models/employees/employeeBranches/EmployeeBranches";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import { mapGetters } from "vuex";

export default {
  name: "EmployeeBranchAdd",
  mixins: [createToastMixin, currentTabMixin],
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  components: {
    PreLoader,
    EmployeeBranchForm,
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeBranches: new EmployeeBranches(),
      employeesArray: [],
      exceptionMsgEmployee: "",
      employees: new Employees(),
    };
  },
  methods: {
    async addOrUpdateEmployeeBranch() {
      this.isLoading = true;
      try {
        let response =
          await this.employeeBranches.employeeBranch.addEmployeeBranches(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.employeeBranches.employeeBranch.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getAllEmployees() {
      this.isLoading = true;
      this.employees.pagination.paginationStatus = false;
      try {
        const response = await this.employees.employee.getEmployees(
          this.language,
          this.userAuthorizeToken,
          this.employees.pagination,
          this.employees.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsgEmployee = null;
          this.employeesData = response.data.employeesPagination.employeesData;

          this.employeesArray = [];
          for (let item in this.employeesData) {
            this.employeesArray.push({
              employeeToken: this.employeesData[item]["employeeToken"],
              employeeCode: this.employeesData[item]["employeeCode"],
              employeeImagePath:
                BASE_URL + this.employeesData[item]["employeeImagePath"],
              employeeNameCurrent:
                this.employeesData[item]["employeeNameCurrent"],
              employeeJobs: [],
            });
          }

          for (let item in this.employeesData) {
            this.employeesData[item]["employeeJobs"] = [];
          }
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeesData = null;
          this.exceptionMsgEmployee = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeesData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeesData = null;
          this.exceptionMsgEmployee = response.data.msg;
        }
        this.isLoading = false;
      } catch (error) {
        this.employeesData = null;
        this.exceptionMsgEmployee = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.employees.filterData.employeeToken = this.employeeToken;
    }
    this.getAllEmployees();
  },
};
</script>
