export default class UsersPrivilegeReports {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reportsView = false;
  }
  fillData(data) {
    if (data) {
      this.reportsView = data.reportsView;
    } else {
      this.setInitialValue();
    }
  }
}
